import { Keys } from '../types';

const keys: Keys = {
  infura: 'daaa68ec242643719749dd1caba2fc66',
  alchemy: '',
  graph: '7c7b4c36244b0b86171a3931eaf9bb23',
  balancerApi: 'da2-7a3ukmnw7bexndpx5x522uafui',
};

export default keys;
