import { Config } from '../types';
import contracts from './contracts';
import keys from './keys';
import pools from './pools';
import rateProviders from './rateProviders';
import tokenlists from './tokenlists';
import tokens from './tokens';

const config: Config = {
  key: '80085',
  chainId: 80085,
  chainName: 'Berachain Artio',
  name: 'Berachain Artio',
  shortName: 'Artio',
  monorepoName: 'artio',
  slug: 'artio',
  network: 'artio',
  trustWalletNetwork: 'artio',
  unknown: false,
  visibleInUI: true,
  testNetwork: true,
  publicRpc: `https://artio.rpc.berachain.com`,
  rpc: `https://artio.rpc.berachain.com`,
  ws: ``,
  explorer: 'https://artio.beratrail.io',
  explorerName: 'Beratrail',
  subgraph:
    'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/artio/0.0.8/gn',
  poolsUrlV2:
    'https://storageapi.fleek.co/johngrantuk-team-bucket/poolsV2.json',
  subgraphs: {
    main: [
      'https://api.goldsky.com/api/public/project_cluukfpdrw61a01xag6yihcuy/subgraphs/artio/0.0.8/gn',
    ],
    aave: '',
    gauge: '',
    blocks: '',
  },
  bridgeUrl: '',
  supportsEIP1559: true,
  supportsElementPools: false, // hides featured ecosystems section under pool list
  blockTime: 12,
  nativeAsset: {
    name: 'BERA',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    symbol: 'BERA',
    decimals: 18,
    deeplinkId: 'bera',
    logoURI: 'tokens/eth.png',
    minTransactionBuffer: '0.05',
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'artio',
      platformId: 'artio',
    },
    apyVision: {
      networkName: 'eth',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 2,
    weight: 100,
  },
  tokenlists,
  rateProviders,
};

export default config;
