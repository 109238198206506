import { TokenConstants } from '../types';

const tokens: TokenConstants = {
  Popular: {
    Symbols: ['HONEY', 'USDC'],
  },
  InitialSwapTokens: {
    input: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // BERA
    output: '0xF5C462bf81a6b6af0f87749eFACe2453c35CB519', // USDC
  },
  Addresses: {
    nativeAsset: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // BERA
    wNativeAsset: '0x5806E416dA447b267cEA759358cF22Cc41FAE80F', // WBERA
    WETH: '0x8239FBb3e3D0C2cDFd7888D8aF7701240Ac4DcA4',
    BAL: '0x9AA488bc4c3c237104CD6661e076B22e0535eCFA',
  },
};

export default tokens;
