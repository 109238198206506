import { TokenListURLMap } from '@/types/TokenList';

const tokenlists: TokenListURLMap = {
  BurrBear: {
    Allowlisted:
      'https://raw.githubusercontent.com/burrbear-dev/tokenlists/berachain-artio/generated/balancer.tokenlist.json',
  },
  External: [],
};

export default tokenlists;
