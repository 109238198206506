import * as artio from '@/assets/data/contracts/artio.json';
import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: artio.MerkleOrchard,
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: artio.Authorizer,
  vault: artio.Vault,
  weightedPoolFactory: artio.WeightedPoolFactory,
  stablePoolFactory: artio.StablePoolFactory,
  lidoRelayer: '',
  balancerHelpers: artio.BalancerHelpers,
  batchRelayer: artio.BalancerRelayer,
  veBAL: '',
  gaugeController: '',
  gaugeFactory: '',
  balancerMinter: '',
  tokenAdmin: '',
  veDelegationProxy: artio.VotingEscrowDelegationProxy,
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '0xD8708572AfaDccE523a8B8883a9b882a79cbC6f2',
  omniVotingEscrow: '',
  claimSubmission: '',
};

export default contracts;
