import * as artio from '@/assets/data/contracts/artio.json';
import { Pools } from '@/types/pools';

const pools: Pools = {
  IdsMap: {},
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [
    '0x0d695b4173affbc21d3deb4502be398f9afaf061000200000000000000000007',
    '0x1f597489244ff0ce103d6998a199eaf1eb96f929000200000000000000000008',
    '0x6c2045c750caf8875f0f64b69ff539b327b1a2e800020000000000000000000d',
    '0x054b8119914c0e1e129d0cd2ce7a4ebeece5947c000200000000000000000014',
    '0xb4c65e6cc501cb9372a5d4fb1de492de6fea32a1000200000000000000000019', // NECT/USDC FX
    '0xe016feca197088659b78e660eaf666031a9fb8b1000000000000000000000006', // Mock_EURS/Mock_USDC CSP
    '0xf0b886478d6c0c579e53facbcc6e4abce96ae4b2000000000000000000000004', // Mock_EURS/Mock_USDC CSP
    '0xb43d9bb28e60e5ede04dfbe2c723a9bcf578397c000000000000000000000005', // Mock_DAI/Mock_USDT CSP
    '0x9610423917403d142705d8d060127f88764120f6000000000000000000000003', // Mock_USDC/Mock_USDT CSP
    // '0x7165941da4b8142fd543907f030697d0df57834f000000000000000000000024', // NECT_mock/HONEY_mock/USDC_mock CSP
  ],
  IncludedPoolTypes: [
    'Weighted',
    // 'Stable',
    // 'MetaStable',
    // 'LiquidityBootstrapping',
    // 'Investment',
    // 'StablePhantom',
    'ComposableStable',
    'FX',
    // 'EulerLinear',
    // 'Gyro2',
    // 'Gyro3',
    // 'GyroE',
  ],
  Stable: {
    AllowList: [],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [],
  },
  Factories: {
    [artio.WeightedPoolFactory]: 'weightedPool',
    [artio.ComposableStablePoolFactory]: 'composableStablePool',
    '0xab5dbbcd27df4f0496bee3c9809fd65f3d813f5b': 'fx', // HONEY_mock FXPoolDeployer
    '0xecdb730e5a58ac94fbac7d0e216727d1732dd554': 'fx', // USDC_mock FXPoolDeployer
    '0xa187b61e9f8f6e58bb0eb8d88cada05710143ce8': 'fx', // HONEY FXPoolDeployer
  },
  Stakable: {
    VotingGaugePools: [],
    AllowList: [],
  },
  Metadata: {},
  Deep: [],
  BoostedApr: [],
  DisabledJoins: [], // may contain pool ids with security issues
  NewVersionAvailable: {},
  Deprecated: {}, // for pool migration?
  GaugeMigration: {},
  BrandedRedirect: {},
  ExitViaInternalBalance: [],
  Issues: {},
};

export default pools;
