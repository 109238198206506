import * as bartio from '@/assets/data/contracts/bartio.json';
import { Pools } from '@/types/pools';

const pools: Pools = {
  IdsMap: {},
  Pagination: {
    PerPage: 10,
    PerPool: 10,
    PerPoolInitial: 5,
  },
  BoostsEnabled: true,
  DelegateOwner: '0xba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1ba1b',
  ZeroAddress: '0x0000000000000000000000000000000000000000',
  DynamicFees: {
    Gauntlet: [],
  },
  BlockList: [
    '0x3383d4d367565eda32f4dc256cae68279aa9dba300020000000000000000000d',
    '0xf0ddc17ff14c4caa505889e41e5270f6cd63fc4e00020000000000000000000c',
    '0x4baab7794cbdadaa51b360604502e59d6f3a6d9a00020000000000000000000e',
    '0xdba381f74bc0a22fe51092f7ceac257965c49654000200000000000000000006',
    '0xc62ba1c49877488ce15b1cf2623d6735eb7d2ce9000200000000000000000009',
    '0xb47f5649338795d1e2086ee5e7cba23b6f3ed5c2000200000000000000000007',
    '0xd8e1927ed35ea4c20fbe9d8e74a56785c5629da400020000000000000000000f',
    '0x3123d6b55828955425b8cdd8927bf0039e369764000100000000000000000010',
    '0xd915d9644da39515eee59e75889bcf9a127fec2b000200000000000000000004',
    '0x473007d0ef224fe6edcac8a0a9228f0b9571f033000200000000000000000005',
    '0xc0df3392fb02369f18b9854c9d4013d574935631000200000000000000000029',
    '0xce31eff0125d96907d5850c421feef40c8b5a275000000000000000000000001',
    '0xce31eff0125d96907d5850c421feef40c8b5a275000000000000000000000001',
    '0xce31eff0125d96907d5850c421feef40c8b5a275000000000000000000000001',
    '0xce31eff0125d96907d5850c421feef40c8b5a275000000000000000000000001',
    '0x1d516d72e82791eaa3a1f58f0d8a3ba4fd3acef3000200000000000000000016',
    '0xc7f6ae40948d78a818faaa43ad32bd3140b2c9ee00020000000000000000001f',
    '0xde32e19e646c3eee76d5eb4a0b58a9b93cae8f85000100000000000000000019',
    '0xa54024b091adf9533041bfab082e3c3c43fc0ed800010000000000000000001b',
    '0xb72b23fe57d7c4b6522b53ce76028b225839346f000200000000000000000026',
    '0x3736948597d348fbffc367aee39f49070a7ef76f000200000000000000000015',
    '0xc41d21d32bafb1951b0d1b74ba9eef1c4968a011000200000000000000000031',
    '0xf015c9de63f2b3f36d52d1e87e10aa5ff6f77304000200000000000000000034',
    '0xe1df7bf03f0668e33879344727bb6c0b95813bdb00020000000000000000002f',
    '0x04e9d731c7f397fa9941dcc3e625abf1d1f3de84000200000000000000000022',
    '0x3abf295ee1408ff468075bf261319b370067897c00010000000000000000002c',
    '0xa24dbc9c100de60984e4683d13f8a6d42bf22dfc00020000000000000000002b',
    '0x22bdfdfe221777e744e4dae3b12a74e15f1851ab000200000000000000000030',
    '0x0c5e81d4bb2e91f7bef35bf47c54e4ddbed64c45000200000000000000000018',
    '0xe130c8ef76c56d734338a42a2e414f5ea2cef785000200000000000000000021',
    '0x1763fa9b9037485320f690b6ccd97ed13677216f00010000000000000000001a',
    '0xba735576c2f670729a23979d485c88a3287c56f7000200000000000000000025',
    '0xacadff8b89a41eaf0d4eea4f9b92d8a756c27dc8000200000000000000000037',
    '0x19d11539d0f10583e595c9c7f39377516da55d9600020000000000000000002d',
    '0xc67624bf08f5a7621a2266ee0b109a642587bfd3000200000000000000000035',
    '0xc6bc90e0bdf4e30a00bb3bad9a91254ee17b1701000200000000000000000032',
    '0x5daa128dd0df701675a452d0004f51a1e97fda85000200000000000000000033',
    '0x6ee311747a2db39e52aee033d7037776c93a4aff000200000000000000000036',
    '0xfa251c5b86fadf40062bfafb9aac2e18f903d8b000020000000000000000000a',
    '0xef6b5a9517636f4c9f5f9c4eeaad41417ce12a2e000200000000000000000013',
    '0xe6ae547ea1276840c06a61a65fcd2753bdc1f0c900010000000000000000001c',
    '0xe3e8c13c47ce5f13a8efd3dd83a13d81be96eb42000100000000000000000017',
    '0xccbe74ee60b512163fd1b63842861a2743b4b9d900010000000000000000003c',
  ],
  IncludedPoolTypes: [
    'Weighted',
    // 'Stable',
    // 'MetaStable',
    // 'LiquidityBootstrapping',
    // 'Investment',
    // 'StablePhantom',
    'ComposableStable',
    'FX',
    // 'EulerLinear',
    // 'Gyro2',
    // 'Gyro3',
    // 'GyroE',
  ],
  Stable: {
    AllowList: [],
  },
  Investment: {
    AllowList: [],
  },
  Weighted: {
    // Only effective after given timestamp here: usePool.ts#createdAfterTimestamp
    // see useDisabledJoinPool.ts#nonAllowedWeightedPoolAfterTimestamp for logic.
    AllowList: [],
  },
  Factories: {
    [bartio.WeightedPoolFactory]: 'weightedPool',
    [bartio.ComposableStablePoolFactory]: 'composableStablePool',
    '0x17d95442085bfa0db18afb7fe1974cbc1cd9cf3c': 'fx', // USDC_mock FXPoolDeployer
    '0x7b59a632c20b0015548cbf61193476664eb900ab': 'fx', // FXPoolDeployer HONEY
    '0x41ad2edb0058a2d7fce7261571d623c5dd8cfea9': 'fx', // FXPoolDeployer hETH
    '0xfc1814dc32f69ea1d99f95af4e5760eb77ac38ef': 'fx', // FXPoolDeployer hSOL
    '0xe68b68dad1a9b012a18a641ae1dc2394d177c714': 'fx', // FXPoolDeployer hTIA
    '0x894fac6c0933cafabb8a0acaa5e196c3b59dac9f': 'fx', // FXPoolDeployer NECT
  },
  Stakable: {
    VotingGaugePools: [],
    AllowList: [],
  },
  Metadata: {},
  Deep: [],
  BoostedApr: [],
  DisabledJoins: [], // may contain pool ids with security issues
  NewVersionAvailable: {},
  Deprecated: {}, // for pool migration?
  GaugeMigration: {},
  BrandedRedirect: {},
  ExitViaInternalBalance: [],
  Issues: {},
};

export default pools;
