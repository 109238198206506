import * as bartio from '@/assets/data/contracts/bartio.json';
import { Contracts } from '../types';

const contracts: Contracts = {
  merkleRedeem: '',
  merkleOrchard: '',
  merkleOrchardV2: bartio.MerkleOrchard,
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: bartio.Authorizer,
  vault: bartio.Vault,
  weightedPoolFactory: bartio.WeightedPoolFactory,
  stablePoolFactory: bartio.StablePoolFactory,
  lidoRelayer: '',
  balancerHelpers: bartio.BalancerHelpers,
  batchRelayer: bartio.BalancerRelayer,
  veBAL: '',
  gaugeController: '',
  gaugeFactory: '',
  balancerMinter: '',
  tokenAdmin: '',
  veDelegationProxy: bartio.VotingEscrowDelegationProxy,
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '0x788Ca7f364c03C3aea1a121D39601188E91e3723',
  omniVotingEscrow: '',
  claimSubmission: '',
};

export default contracts;
