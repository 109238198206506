import { TokenConstants } from '../types';

const tokens: TokenConstants = {
  Popular: {
    Symbols: ['XSGD', 'USDC'],
  },
  InitialSwapTokens: {
    input: '0x7A73FA0Be231B44dbcA23E98F49CAe7F11f367Ba', // XSGD
    output: '0xf3585984B956f2A26694ee1BAede7eAB658A5e7E', // USDC
  },
  Addresses: {
    nativeAsset: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE', // BERA
    wNativeAsset: '0x7507c1dc16935B82698e4C63f2746A2fCf994dF8', // WBERA
    WETH: '0x6E1E9896e93F7A71ECB33d4386b49DeeD67a231A',
    BAL: '0x2DaA47A2cE0BE7484C6328e20eE200e901fEf443',
  },
};

export default tokens;
